.collapse-wrapper {
    margin-top: 1rem;
    border: 1px solid white;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px) saturate(1.5);
}

.collapse-button {
    width: 100%;
    padding: 1rem;
}

.collapse-button:hover {
    text-decoration: underline;
}

.collapse-content {
    padding-left: 1rem;
    padding-right: 1rem;
}

.collapse-external-link {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.collapse-external-link:hover {
    border-color: rgba(255, 255, 255, 1);
}
