#tsparticles canvas {
    filter: blur(80px);
}

.home-image {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: block;
    object-fit: contain;
    object-position: initial;
    filter: none;
    margin: 0 auto;
}

.external-link {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-top: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px) saturate(1.2);
}

.external-link:hover {
    text-decoration: underline;
}
