body.visuals-page {
    background-color: black;
}

body.visuals-page #root {
    background-color: transparent;
    border-radius: 0;
}

body.visuals-page aside {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    opacity: 0.5;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

body.visuals-page aside label {
    margin: 0;
    line-height: 1;
}

body.visuals-page p {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    opacity: 0.5;
    line-height: 1;
    font-size: 2rem;
    font-family: 'Noto Serif Display', serif;
}
